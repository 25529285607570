import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';

import Accordion from './accordion/Accordion';
import Card from './accordion/Card';
import Header from './accordion/Header';
import Collapse from './accordion/Collapse';
import Icon from './accordion/Icon';

import '../../css/components/common/ParagraphAccordion.css';

import CloseCard from '../../images/close_card.svg';
import OpenCard from '../../images/open_card.svg';

class ParagraphAccordion extends Component {
    render() {
        const isToggle = this.props.isToggle;
        const title = this.props.title?this.props.title:'No title';
        const id = this.props.id;
        const className = this.props.className?this.props.className:''
        const dr = <Image src={ CloseCard }/>        
        const dd = <Image src={ OpenCard } />
        return (
            <Accordion className={ `paragraph-accordion ${ className }` } id={ id }>
                <Card isToggle={ isToggle }>
                    <Header eventKey='0'>
                        <Icon open={dd} close={dr}>
                            <h1>{ title }</h1>
                        </Icon>
                    </Header>
                    <Collapse eventKey='0'>
                        { this.props.children }
                    </Collapse>
                </Card>
            </Accordion>);
    } 
}

export default connect()(ParagraphAccordion);