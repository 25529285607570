import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class Accordion extends Component {
    
    render() {
        const className = this.props.className?this.props.className:'';
        const id = this.props.id;
        return <Container fluid={true} className={ `accordion-cm ${className}` } id={ id }>
                    <Row>
                        <Col>{ this.props.children }</Col>
                    </Row>
                </Container>;
    }
}

export default Accordion;