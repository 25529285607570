import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Paragraph from '../common/Paragraph';
import { Formik } from 'formik';
import * as yup from 'yup';
import BackgroundImg from '../../components/common/BackgroundImg';

import '../../css/components/paragraphs/Meeting.css';

import { 
    sendForm,
    showFromPopup, 
} from '../../actions'

import Img from '../../images/meeting.svg';

import { ANCHOR_MEETING } from '../../constants'

class Meeting extends Component {
    render() {
        const texts = this.props.texts;
        return (
            <Paragraph title={ texts.title } id={ ANCHOR_MEETING } className='meeting'>
                <BackgroundImg image={ Img } className='d-none d-lg-block' />
                <Row>
                    <Col md={12} className='meeting-form'>
                        <Row>
                            <Col><p>{ texts.text }</p></Col>
                        </Row>
                        <Row>
                            <Col>
                            { this.getForm(texts) }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='d-block d-lg-none meeting-image-down'>
                    <Col className='d-flex justify-content-center'>
                        <img  src={ Img } alt={ texts.title }></img>
                    </Col>
                </Row>
            </Paragraph>
        );
    }

    getForm(texts) {
        const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
        const schema = yup.object({
            phone: yup.string().matches(phoneRegExp, texts.errors.invalidPhone).required(texts.errors.requiredPhone),
            email: yup.string().email(texts.errors.invalidEmail).required(texts.errors.requiredEmail),
        });
        return <Formik
                    validationSchema={schema}
                    onSubmit={ values=>this.onSubmit(values) }
                    initialValues={{
                        'phone':'',
                        'email':'',
                        'details':'',
                    }}
                >
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
            }) => (
                    <Form noValidate onSubmit={handleSubmit} className='offer-form'>
                        <Form.Row className='meeting-inputs'>
                            <Col sm={6} xs={12}>
                                <Form.Group as={Col} controlId='formPhone'>
                                    <Form.Label>{ texts.phone }</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='phone'
                                        placeholder={texts.placeholders.phone}
                                        value={values.phone}
                                        onChange={handleChange}
                                        isValid={touched.phone && !errors.phone}
                                        isInvalid={!touched.phone && errors.phone}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Form.Group as={Col} controlId='formEmail'>
                                    <Form.Label>{ texts.email }</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='email'
                                        placeholder={texts.placeholders.email}
                                        value={values.email}
                                        onChange={handleChange}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={!touched.email && errors.email}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId='formDetails'>
                                <Form.Label>{ texts.details }</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    name='details'
                                    value={values.details}
                                    placeholder={texts.placeholders.details}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Button type='submit' className='form-submit-btn' disabled={ !isValid }>
                                {texts.button}
                            </Button>
                        </Form.Row>
                    </Form>
                )}
        </Formik>
    }

    onSubmit(values) {
        console.log(values)
        this.props.sendForm(values)
    }
}

const mapStateToProps = state => {
    return {
        formStatus:      state.form.status,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        sendForm: values => {
            dispatch(showFromPopup())
            dispatch(sendForm(values));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);