export const ANCHOR_CONTACTS        = 'contacts';
export const ANCHOR_MEETING         = 'meeting';
export const ANCHOR_PROJECTS        = 'projects';
export const ANCHOR_TECHNOLOGIES    = 'technologies';
export const ANCHOR_TESTIMONIALS    = 'testimonials';
export const ANCHOR_WHY             = 'why';

export const FORM_STATUS_IDLE       = 'formStatusIdle';
export const FORM_STATUS_SENDING    = 'formStatusSending';
export const FORM_STATUS_SENT       = 'formStatusSent';

export const LANGUAGE_EN  = 'languageEN';
export const LANGUAGE_SE  = 'languageSE';

export const CONTAINER_ID  = 'mainContainer';

export const GOOGLE_MAP_KEY = 'AIzaSyBd6HNn_ygKL8AeTMJb1QCpexky1CGYoUc';

export const MOBILE_SIZE = 767.98;