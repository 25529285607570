import React,{Component} from 'react';
import { Row, Col } from 'react-bootstrap';
import Paragraph from '../common/Paragraph';
import Map from '../Map';
import ContactList from '../common/ContactList';

import '../../css/components/paragraphs/Contact.css';

import { ANCHOR_CONTACTS } from '../../constants'

class Contact extends Component {
    render() {
        const texts = this.props.texts;
        return (
            <Paragraph title={ texts.title } id={ ANCHOR_CONTACTS } className='contact'>
                <Row>
                    <Col sm={ 7 }><Map/></Col>
                    <Col sm={ 5 }>
                        <ContactList texts={ texts } />
                    </Col>
                </Row>
            </Paragraph>
        );
    }

}

export default Contact;