import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Paragraph from '../common/Paragraph';
import ParagraphAccordion from '../common/ParagraphAccordion';

import '../../css/components/paragraphs/Testimonials.css';

import { ANCHOR_TESTIMONIALS, MOBILE_SIZE } from '../../constants';

class Testimonials extends Component {
    render() {
        const texts = this.props.texts;
        const list = this.generateList(texts)

        if (this.props.size.width > MOBILE_SIZE ) {
            return (
                <Paragraph title={ texts.title } id={ ANCHOR_TESTIMONIALS } className='testimonials'>
                    <Row>
                        <Col>{ list }</Col>
                    </Row>
                </Paragraph>
            );
        } else {
            return (
                <ParagraphAccordion title={ texts.title } id={ ANCHOR_TESTIMONIALS } className='testimonials'>
                    <Row className="testimonials-collapse">
                        <Col>{ list }</Col>
                    </Row>
                </ParagraphAccordion>
            );
        }
    }
    
    generateList(texts) {
        const smSize = Math.floor(12/texts.content.length)
        return <Row> 
            { texts.content.map((item, i) => {
                return (
                    <Col  md={12} lg={ smSize } key={ item.title + i } className='testimonials-blok'>
                        <Row className='testimonials-item'>
                            <Col>
                                <Row>
                                    <Col 
                                        className='testimonials-title'>
                                            { item.title }
                                    </Col>
                                </Row>
                                <Row> 
                                    <Col className='d-flex contact-list-content align-items-end'>
                                            { item.sub.map((sub, i) => {
                                                    return (
                                                    <p key={ sub + i }
                                                        className='testimonials-sub'>
                                                            { sub }
                                                    </p>
                                                )}
                                            )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col 
                                        className='testimonials-text'>
                                            { item.text }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                );
            }) 
        }
        </Row>
    }
}


const mapStateToProps = state => {
    return {
        size:    state.size,
        texts:   state.texts.testimonials,
    };
}

export default connect(mapStateToProps)(Testimonials);