 const SE = {
     "logo": {
        "shield": "Shield",
        "technologies": "Technologies",
     },
     "language": {
        "en": "EN",
        "se": "SE",
     },
     "header": {
        "title": "Digitala lösningar för hållbar tillväxt",
        "text": "Vi är riktigt bra på att skapa digitala lösningar som får företag att växa, såväl stora som små. Hör av dig så bokar vi in ett möte och ser hur vi kan hjälpa dig.",
        "bookBtn": "Boka ett möte",
        "items": {
            "web": "Hemsidor",
            "sales": "Säljverktyg",
            "ecommerce": "E-handel",
            "mobile": "Mobila lösningar",

        }
    },
    "menu": {
        "services": "Tjänster",
        "projects": "Kunder & Case",
        "why": "Varför Shield?",
        "contacts": "Kontakt",
    },
    "technologies": {
        "title": "Tekniker",
        "technologies": [{
                "title": "Frontend",
                "list": ["Javascript", "HTML", "CSS"]
            },{
                "title": "Backend",
                "list": ["Golang", "Rust", "Ruby", "PHP"]
            },{
                "title": "Generell utveckling",
                "list": ["C", "C++", "Java"]
            },{
                "title": "Mobila Lösningar",
                "list": ["IOS", "Swift", "Java"]
            }
        ]
    },
    "projects":{
        "title": "Tidigare projekt",
    },
    "why":{
        "title": "Varför Shield?",
        "list":[{
                "title": "Alltid nöjda kunder",
                "text": "Vi jobbar stenhårt för att ni ska bli riktigt nöjda, det är det enda som räknas i slutändan."
            },{
                "title": "Passion för utveckling och design",
                "text": "Vi älskar teknik, användarupplevelser och design. Varje kodrad är skriven med kärlek och varje färg är vald med omsorg."
            },{
                "title": "Vi älskar det vi gör",
                "text": "Vi är inte bara riktigt bra på det vi gör, vi har roligt också. Det gagnar faktiskt dig eftersom vi lägger ned kärlek i varje projekt."
            }
        ]
    },
    "testimonials": {
        "title":"Vad säger våra kunder?",
        "content":[
            {
                "title": "Gunnar",
                "sub": ["Cell Solar"],
                "text": "Vi på Cell Solar är väldigt nöjda med Shield Technologies och resultatet de levererade, mjukvaran håller hög kvalitet och arbetet fortskred enligt planerna."
            },{
                "title": "Anders",
                "sub": ["Xenter"],
                "text": "Vi är väldigt nöjda med Shield Technologies och kan varmt rekommendera dem till framtida uppdrag."
            }
        ]
    },
    "meeting": {
        "title": "Boka ett möte ",
        "text": "Det är klart du ska boka ett möte, vi finns både fysiskt och på telefon, helt efter dina önskemål. Vi svarar alltid inom 24 timmar.",
        "email": "E-post",
        "phone": "Telefon",
        "details": "Meddelande (valfritt)",
        "button": "Boka nu",
        "placeholders":{
            "email": "name@email.com",
            "phone": "+46 (__) __ - __ - ___",
            "details": "Vad behöver du hjälp med?",

        },
        "errors":{
            "requiredEmail": "Email is required",
            "invalidEmail": "Email is invalid",
            "requiredPhone": "Phone is required",
            "invalidPhone": "Phone number is invalid",
        },
        "form": {
            "title": "Tack för att du kontaktar oss",
            "text": "Vi återkommer inom 24 timmar.",
        }
    },
    "contact": {
        "title": "Kontakt",
        "address": "Sankt Eriksgatan 72, 113 20 Stockholm",
        "phone": "+46 732 688 937",
        "email": "info@theshield.se"
    },
    "footer": {
        "address": "Sankt Eriksgatan 72, 113 20 Stockholm",
        "phone": "+46 732 688 937",
        "email": "info@theshield.se",
        "copyright": "Copyright © 2021 Shield Technologies",
        "terms": "Terms of Service & Privacy Policy"
    }
};

export default SE;
