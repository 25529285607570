import React,{Component} from 'react';
import { Row, Col } from 'react-bootstrap';
import Paragraph from '../common/Paragraph';

import '../../css/components/paragraphs/Technologies.css';

import { ANCHOR_TECHNOLOGIES} from '../../constants'

class Technologies extends Component {
    render() {
        const texts = this.props.texts;
        const list = this.generateList(texts)
        return (
            <Paragraph title={ texts.title } id={ ANCHOR_TECHNOLOGIES } className='technologies'>
                <Row>
                    <Col>{ list }</Col>
                </Row>
            </Paragraph>
        );
    }
    
    generateList(texts) {
        const smSize = Math.floor(12/texts.technologies.length)
        const xsSize = Math.floor(12/(texts.technologies.length/2))
        return <Row> 
            { texts.technologies.map((item, i) => {
                return (
                    <Col  sm={ smSize } xs={ xsSize } key={ item.title + i } className='technology' >
                        <Row className='technology-title'>
                            <Col>
                                <h2>{ item.title }</h2>
                            </Col>
                        </Row>
                        <Row> <Col>
                            { item.list.map((tech, i) => {
                                    return (
                                        <Row key={ tech + i } className='technology-tech'>
                                            <Col>
                                                    { tech }
                                            </Col>
                                        </Row>
                                )}
                            )}
                            </Col>
                        </Row>
                    </Col>
                );
            }) 
        }
        </Row>
    }
}

export default Technologies;