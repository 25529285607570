import React,{Component} from 'react';

import Pointer from '../../images/pointer.svg';

class MapPointer extends Component {
    render() {
        const text = this.props.text;
        return (
            <div className='map-pointer'>
                <p>{ text }</p>
                 <img alt='Sankt Erikgsgatan 72, 113 20 Stockholm' src={ Pointer }></img> 
            </div>
        );
    }
}

export default MapPointer;
