import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

class Paragraph extends Component {
    render() {
        const title = this.props.title?this.props.title:'No title';
        const id = this.props.id;
        const className = this.props.className?this.props.className:'paragraph'
        return (
                <Row className={ className } id={ id }>
                    <Col>
                        <Row>
                            <Col>
                                <h1>{ title }</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                { this.props.children }
                            </Col>
                        </Row>
                    </Col>
                </Row>
        );
    } 
}

export default connect()(Paragraph);