import React,{Component} from 'react';
import { Row, Col } from 'react-bootstrap';

import '../../css/components/common/ContactList.css';

import Address from '../../images/addres.svg';
import Phone from '../../images/phone.svg';
import Email from '../../images/email.svg';

class ContactList extends Component {
    render() {
        const texts = this.props.texts;
        return (
            <Row className='contact-list'>
                <Col>
                    <Row className='contact-list-address'>
                        <Col className='contact-list-content d-flex align-items-end'>
                            <div><img src={ Address } alt={ texts.address }></img></div>
                            <div><p>{ texts.address }</p></div>
                        </Col>
                    </Row>
                    <Row className='contact-list-phone'>
                        <Col className='contact-list-content d-flex align-items-end'>
                            <div><img src={ Phone } alt={ texts.phone }></img></div>
                            <div><a href={ `tel:${ texts.phone }` }><p>{ texts.phone }</p></a></div>
                        </Col>
                    </Row>
                    <Row className='contact-list-email'>
                        <Col className='contact-list-content d-flex align-items-end'>
                            <div><img src={ Email } alt={ texts.email }></img></div>
                            <div><a href={ `mailto:${ texts.email }` }><p>{ texts.email }</p></a></div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    } 
}

export default ContactList;