import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import HeaderItem from './common/HeaderItem';
import Logo from './common/Logo';
import Menu from './common/Menu';
import Language from './Language';

import '../css/components/Header.css';

import Img from '../images/header_img.svg';
import Down from '../images/down.svg';
import Web from '../images/web.svg';
import Sales from '../images/sales.svg';
import Ecomerce from '../images/ecomerce.svg';
import Mobile from '../images/mobile.svg';
import BackgroundImg from '../components/common/BackgroundImg';
import MenuImg from '../images/menu_mobile.svg';

import { openMobileMenu } from '../actions';

import { 
    ANCHOR_MEETING,
} from '../constants';

class Header extends Component {
    render() {
        const headerTxt = this.props.headerTxt;
        const logoTxt = this.props.logoTxt;
        const menuTxt = this.props.menuTxt;
        const languageTxt = this.props.languageTxt;
        return (
            <Row className='header'>
                <BackgroundImg image={ Img }/>
                <Col>
                    <Row className='header-top'>
                        <Col md={4} xs={6}>
                            <Logo texts={ logoTxt } />
                        </Col>
                        <Col className='d-none d-md-flex align-items-end'>
                            <Menu texts={ menuTxt } className=''/>
                        </Col>
                        <Col md={3} className='d-none d-md-flex align-items-end justify-content-end'>
                            <Language texts={ languageTxt }/>
                        </Col>
                        <Col xs={6} className='header-menu-image d-flex d-md-none align-items-end justify-content-end'>
                            <img src={ MenuImg } onClick={ this.props.onMenuButtonClick } alt='menu'></img>
                        </Col>
                    </Row>
                    <Row className='header-content'>
                        <Col className='header-texts'>
                            <h1>{ headerTxt.title }</h1>
                            <p> { headerTxt.text } </p>
                        </Col>
                        <Col md={6}className='d-none d-md-block'>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='header-button'>
                            <Button onClick={ ()=>this.goToAnchor(ANCHOR_MEETING) }>{ headerTxt.bookBtn }</Button>
                        </Col>
                    </Row>
                    <Row >
                        <Col className='d-none d-md-flex justify-content-center header-down'> 
                            <img src={ Down } alt='scroll down'></img>
                        </Col>
                    </Row>
                    <Row className='header-items'>
                        <Col className='my-auto' xs={6} md={3}>
                            <HeaderItem 
                                title={ headerTxt.items.web } 
                                image={ Web }
                            />
                        </Col>
                        <Col  className='my-auto' xs={6} md={3}>
                            <HeaderItem 
                                title={ headerTxt.items.sales } 
                                image={ Sales }
                            />
                        </Col>
                        <Col  className='my-auto' xs={6} md={3}>
                            <HeaderItem 
                                title={ headerTxt.items.ecommerce } 
                                image={ Ecomerce }
                            />
                        </Col>
                        <Col className='my-auto' xs={6} md={3}>
                            <HeaderItem 
                                title={ headerTxt.items.mobile } 
                                image={ Mobile }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    goToAnchor(anchor) {
        window.location.href = `#${ anchor }`;
    }
}

const mapStateToProps = state => {
    return {
        headerTxt:      state.texts.header,
        logoTxt:        state.texts.logo,
        menuTxt:        state.texts.menu,
        languageTxt:    state.texts.language,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onMenuButtonClick: () => dispatch(openMobileMenu()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);