import React,{Component} from 'react';
import GoogleMapReact from 'google-map-react';
import MapPointer from './common/MapPointer';
import { GOOGLE_MAP_KEY } from '../constants';

class Map extends Component {
    render() {
        let center = {
            lat: 59.33882, //59.3403845,
            lng: 18.03629  //18.0402274
        }

        if (this.props.center) {
            center = this.props.center;
        }

        return (
            <div className='map'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                    center={center}
                    zoom={17}
                    options={this.getMapOptions}
                >
                    <MapPointer
                        lat={center.lat}
                        lng={center.lng}
                        text='Shield Technologies'
                    />
                </GoogleMapReact>
            </div>
        );
    }

    getMapOptions = (maps) => {
        return {
            streetViewControl: false,
            scaleControl: false,
            fullscreenControl: false,
            gestureHandling: 'none',
            styles: [{
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [{
                    visibility: 'on'
                }]
            }],
            //gestureHandling: 'greedy',
            //disableDoubleClickZoom: true,

            mapTypeControl: false,
            mapTypeId: maps.MapTypeId.ROADMAP,
            zoomControl: false,
            clickableIcons: false,
        };
    }
}

export default Map;
