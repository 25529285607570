import React,{Component} from 'react';


import '../../css/components/common/BackgroundImg.css';

class BackgroundImage extends Component {
    render() {
        const className = this.props.className?this.props.className +' background-img':'background-img';
        const img = this.props.image;
        return (
            <div className={ className }>
                <img  src={ img } alt=''></img>
            </div>
        );
    } 
}

export default BackgroundImage;