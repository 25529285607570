import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class Collapse extends Component {
    constructor(props) {
        super(props);
        this.state = { toggle:this.props.isToggle, initialized: false };
        this.col = React.createRef();
        this.styleInit = false;
    }
    render() {
        const className = this.props.className ? this.props.className : '';
        const children = this.props.children ? this.props.children : '';
        const hideStyle = this.getToggleStile(this.state.toggle, this.styleInit);
        this.styleInit = this.state.initialized;
        return <Row style={{overflow: 'hidden'}} className={`collapse-cm ${className}`}>
            <Col ref={ this.col } style={hideStyle}>{children}</Col>
        </Row>
    }

    componentDidMount() {
        window.addEventListener('load', this.onLoaded());
    }
    
    componentWillUnmount() { 
       window.removeEventListener('load', this.onLoaded())  
    }

    onLoaded() {
        this.setState({initialized:true});
        this.toggle(this.state.toggle);
    }

    toggle(show) {
        const t = show === undefined ? !this.state.toggle : show;
        this.setState({ toggle: t });
        return t;
    }

    getToggleStile(toggle, isTransition) {
        const height = this.col.current?this.col.current.clientHeight+'px':'100%';
        const style = {};
        if (isTransition) {
            style.transition = 'margin-top .5s ease-out';
        }
        if (toggle) {
            style.marginTop = '0';
        } else {
            style.marginTop = `-${height}`; 
        } 
        return style;
    }

}

export default Collapse;