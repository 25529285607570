import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Logo from './common/Logo';
import Menu from './common/Menu';
import ContactList from './common/ContactList';

import '../css/components/Footer.css';

class Footer extends Component {
    render() {
        const texts = this.props.footerTxt;
        const logoTxt = this.props.logoTxt;
        const menuTxt = this.props.menuTxt;
        return (
            <Row className='footer'>
                <Col>
                    <Row>
                        <Col md={ 6 }>
                            <Row>
                                <Col className='d-flex justify-content-center justify-content-md-start'> 
                                    <Logo texts={ logoTxt } />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='footer-menu d-none d-md-flex align-items-end'> 
                                    <Menu texts={ menuTxt } />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={ 6 } className='d-flex justify-content-end'>
                            <ContactList texts={ texts } />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='copyright'>
                            { texts.copyright }
                        </Col>
                    </Row>
                    <Row>
                        <Col className='terms'>
                            { texts.terms }
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    } 
}

const mapStateToProps = state => {
    return {
        footerTxt: state.texts.footer,
        logoTxt: state.texts.logo,
        menuTxt: state.texts.menu,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);