import React,{Component} from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Paragraph from '../common/Paragraph';
import '../../css/components/paragraphs/Projects.css';

import { ANCHOR_PROJECTS } from '../../constants'

import Cellsolar from '../../images/cellsolar.svg';
import Xenter from '../../images/xenter.svg';
import Sih from '../../images/sih.svg';
import Botkyrka from '../../images/botkyrka.svg';

class Projects extends Component {
    render() {
        const texts = this.props.texts;
        return (
            <Paragraph title={ texts.title } id={ ANCHOR_PROJECTS } className='projects'>
                <Row className='projects-list'>
                    <Col lg={3} md={6}> 
                        <Row className='justify-content-md-center'>
                            <Col className='cellsolar projects-item'>
                                <Image onClick={ () => this.onLogoClick('https://cellsolar.se') } src={ Cellsolar }/>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3} md={6}>
                        <Row className='justify-content-md-center'> 
                            <Col className='xenter projects-item'>
                                <Image onClick={ () => this.onLogoClick('https://www.xenter.se') } src={ Xenter }/>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2} md={6}>
                        <Row className='justify-content-md-center'> 
                            <Col className='sih projects-item'>
                                <Image onClick={ () => this.onLogoClick('https://sih.se') } src={ Sih }/>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} md={6}> 
                        <Row className='justify-content-md-center projects-item'>
                            <Col className='botkyrks projects-item'>
                                <Image onClick={ () => this.onLogoClick(' https://www.botkyrka.se') } src={ Botkyrka }/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Paragraph>
        );
    }

    onLogoClick(url) {
        //window.top.location.href = url;
        window.open(url, '_blank');
    }
}

export default Projects;