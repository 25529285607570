import React, { Component } from 'react';
 
import '../../css/components/common/Screen.css';

class Screen extends Component {
    render() {
        let overlay = null;
        if (this.props.overlay) {
            overlay = <div className='screen-overlay'>
                    { this.props.overlay }
                    </div>
        }

        const className = 'screen-container';
        
        return <div ref={ this.props.divRef } onClick={ this.props.onClick } className={ `screen ${ this.props.className }` }>
                <div className={className}>
                    { this.props.content }
                </div>
                { overlay }
        </div>
    }
}

export default Screen;