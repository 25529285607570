import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { LANGUAGE_EN, LANGUAGE_SE } from '../constants';

import { changeLanguage } from '../actions';

import '../css/components/Language.css';

import Sweden from '../images/flag_sweden.svg';
import UnitedKingdom from '../images/flag_united_kingdom.svg';

class Language extends Component {
    render() {
        const enClass = this.props.language === LANGUAGE_EN?'selected':'not-selected';
        const seClass = this.props.language === LANGUAGE_SE?'selected':'not-selected';
        const texts = this.props.texts;
        const className = this.props.className?this.props.className:'';
        return<Row className={ `${ className } language` }>
            <Col>
                <div><img src={ UnitedKingdom } height='15px' alt={ texts.en }></img></div>
                <div><p className={enClass} onClick={()=>this.changeLanguage(LANGUAGE_EN)}>{ texts.en }</p></div>
                <div className='vertical-line '></div>
                <div><p className={seClass} onClick={()=>this.changeLanguage(LANGUAGE_SE)}>{ texts.se }</p></div>
                <div><img src={ Sweden } height='15px' alt={ texts.se }></img></div>
            </Col>
        </Row>
    }

    changeLanguage(lang) {
        if (this.props.language === lang) {
            return;
        }
        this.props.changeLanguage(lang);
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
}

const mapStateToProps = state => {
    return {
        language: state.language,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changeLanguage: lang => dispatch(changeLanguage(lang)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Language);