import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon      from './Icon';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { toggled: this.props.isToggle };
    }
    render() {
        this.icons = [];
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (child.type === Icon ) {
                const collRef = React.createRef();
                this.icons.push(collRef);
                return React.cloneElement(child, { ref: collRef, isToggle:this.props.isToggle })
            }
            return child;
        });
        
        const cn = this.props.className?this.props.className:'';
        return <Row className={ `header-cm ${cn}` }>
                    <Col onClick={ 
                            () => this.onItemClick(this.props.eventKey) 
                        }>
                    { childrenWithProps }</Col>
                </Row>
    }

    onItemClick(key) {
            this.props.onClick(key, this);
    }

    toggled(t) {
        this.icons.forEach(icon => {
            icon.current.toggle(t);
        });
    }
}

export default Header;