export const ON_SIZE_CHANGE = 'ON_SIZE_CHANGE';
export const onSizeChange = (width, height) => ({
    type: ON_SIZE_CHANGE,
    payload: {
        width,
        height
    }
});

export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU';
export const openMobileMenu = () => ({
    type: OPEN_MOBILE_MENU
});

export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';
export const closeMobileMenu = () => ({
    type: CLOSE_MOBILE_MENU
});

export const SHOW_FORM_POPUP = 'SHOW_FORM_POPUP';
export const showFromPopup = () => ({
    type: SHOW_FORM_POPUP
});

export const HIDE_FORM_POPUP = 'HIDE_FORM_POPUP';
export const hideFromPopup = () => ({
    type: HIDE_FORM_POPUP
});

export const  SEND_FORM         = ' SEND_FORM';
export const  SEND_FORM_ERROR   = 'SEND_FORM_ERROR';
export const sendForm = (fromData) => async dispatch => {
    try{
        const response = await fetch('https://theshield.se/email.php', {
            method: 'POST',
            headers: {
                'Content-Type':'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(fromData),
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({
                type:  SEND_FORM,
                payload: data
            });
        } else {
            dispatch({
                type: SEND_FORM_ERROR,
                payload: response.status
            });
        }
    } catch(err) {
        dispatch({
            type: SEND_FORM_ERROR,
            payload: `Fetch error:${ err }`
        });
    }
}

export const  SEND_FORM_MOCK    = ' SEND_FORM';
const sendFormResp = function() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  }
export const sendFormMock = (from) => async dispatch => {
    dispatch(showFromPopup());
    try{
        const response = await sendFormResp();
        dispatch({
            type: SEND_FORM_MOCK,
            payload: response
        });
    }  catch(err) {
        dispatch({
            type: SEND_FORM_ERROR,
            payload: `Fetch error:${ err }`
        });
    }
}

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const changeLanguage = lang => ({
    type: CHANGE_LANGUAGE,
    payload: lang,
});