import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class Collapse extends Component {
    constructor(props) {
        super(props);
        this.state = { toggle: this.props.isToggle };
    }
    render() {
        const open = React.cloneElement(this.props.open);
        const close = React.cloneElement(this.props.close);
        let icon = this.state.toggle?open:close;
        return  <Row className={ `icon-cm ${this.props.className}` }>
                    <Col xs={ 10 } sm={ 11 } className='icon-cm-col'>{ this.props.children }</Col>
                    <Col xs={ 2 } sm={ 1 } className='icon-cm-col-icon d-flex justify-content-end align-items-center'>{ icon }</Col>
                </Row>
    }

    toggle(show) {
        const t = show === undefined?!this.state.toggle:show;
        this.setState({ toggle: t });
        return t;
    }
}

export default Collapse;