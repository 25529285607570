import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import Img from '../../images/formPopup.svg';
import Close from '../../images/cancel_mobile.svg';

import '../../css/components/common/FormPopup.css'

import { hideFromPopup } from '../../actions'

class FormPopup extends Component {
    render() {
        const meetingFormTxt = this.props.meetingFormTxt;
         return (
            <Container 
                fluid={ true } 
                className='form-popup h-100 d-flex justify-content-center align-items-center'
                onClick={ ()=>this.props.onCloseButtonClick()}>
                <Row className='form-popup-container'>
                    <Col lg={12} className='d-flex justify-content-center'>
                        <h1>{ meetingFormTxt.title }</h1>
                    </Col>
                    <Col lg={12} className='d-flex justify-content-center'>
                        <p>{ meetingFormTxt.text }</p>
                    </Col>
                    <Col lg={12} className='d-flex justify-content-center'>
                        <img  src={ Img } alt={ meetingFormTxt.title  }></img>
                    </Col>

                    <div className='form-popup-close'>
                        <img  src={ Close } onClick={ ()=>this.props.onCloseButtonClick()} alt='close'></img>
                    </div>
                </Row>
            </Container>
        );
    } 
}

const mapStateToProps = state => {
    return {
        meetingFormTxt:        state.texts.meeting.form,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onCloseButtonClick: () => dispatch(hideFromPopup()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPopup);