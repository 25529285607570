import React,{Component} from 'react';

import LogoImg from '../../images/logo.svg';

import '../../css/components/common/Logo.css';

class Logo extends Component {
    render() {
        const texts = this.props.texts;
        return (
            <div className='logo'>
                <div className='logo-img column'>
                    <img src={ LogoImg } alt={ `${ texts.shield } ${ texts.technologies }` }></img>
                </div>
                <div className='logo-text column'>
                    <p><b>{ texts.shield }</b></p>
                    <p>{ texts.technologies }</p>
                </div>
            </div>
        );
    } 
}

export default Logo;