import  EN  from '../texts/en';
import  SE  from '../texts/se';
import { LANGUAGE_SE } from '../constants';
import { 
    OPEN_MOBILE_MENU, 
    CLOSE_MOBILE_MENU, 
    SEND_FORM_MOCK,
    CHANGE_LANGUAGE,
    SHOW_FORM_POPUP,
    HIDE_FORM_POPUP,
    ON_SIZE_CHANGE,
} from '../actions';
import { FORM_STATUS_IDLE, FORM_STATUS_SENT} from '../constants';

const defaultState = {
    texts: SE,
    language: LANGUAGE_SE,
    mobile: {menuShow: false},
    form: {status: FORM_STATUS_IDLE, showPopup:false},
    size: {width:800, height:500}
}

export const root = (state = defaultState, action) => {
    switch(action.type) {
        case ON_SIZE_CHANGE: {
            return Object.assign({}, state, {
                size: {
                    width:  action.payload.width,
                    height: action.payload.height,
                }
            })
        }
        case OPEN_MOBILE_MENU: {
            return Object.assign({}, state, {
                mobile: {
                    menuShow: true 
                }
            })
        }
        case CLOSE_MOBILE_MENU: {
            return Object.assign({}, state, {
                mobile: {
                    menuShow: false 
                }
            })
        }
        case SEND_FORM_MOCK: {
            return Object.assign({}, state, {
                form: Object.assign({}, state.form, {
                    status: FORM_STATUS_SENT 
                })
            })
        }
        case CHANGE_LANGUAGE: {
            let language;
            if (action.payload === LANGUAGE_SE) {
                language = SE;
            } else {
                language = EN;
            }
            return Object.assign({}, state, {
                texts: language,
                language: action.payload,
            })
        }
        case SHOW_FORM_POPUP: {
            return Object.assign({}, state, {
                form: Object.assign({}, state.form, {
                    showPopup: true  
                })
            })
        }
        case HIDE_FORM_POPUP: {
            return Object.assign({}, state, {
                form: Object.assign({}, state.form, {
                    showPopup: false  
                })
            })
        }
        default:
        return state;
    }
}