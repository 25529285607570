import React,{Component} from 'react';
import { Nav } from 'react-bootstrap';

import '../../css/components/common/Menu.css';

import { 
    ANCHOR_CONTACTS,
    ANCHOR_TECHNOLOGIES,
    ANCHOR_WHY,
    ANCHOR_PROJECTS,
} from '../../constants';

class Menu extends Component {
    render() {
        const texts = this.props.texts;
        const onClick = this.props.onClick;
        return (
            <Nav defaultActiveKey='#home' as='ul' className={`menu ${ this.props.className }`}>
                <Nav.Item as='li'>
                    <Nav.Link href={ `#${ ANCHOR_TECHNOLOGIES }` } onClick={onClick}>{ texts.services }</Nav.Link>
                </Nav.Item>
                <Nav.Item as='li'>
                    <Nav.Link href={ `#${ ANCHOR_PROJECTS }` } onClick={onClick}>{ texts.projects }</Nav.Link>
                </Nav.Item>
                <Nav.Item as='li'>
                    <Nav.Link href={ `#${ ANCHOR_WHY }` } onClick={onClick}>{ texts.why }</Nav.Link>
                </Nav.Item>
                <Nav.Item as='li'>
                    <Nav.Link href={ `#${ ANCHOR_CONTACTS }` } onClick={onClick}>{ texts.contacts }</Nav.Link>
                </Nav.Item>
            </Nav>
        );
    } 
}

export default Menu;