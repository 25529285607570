import React,{Component} from 'react';
import { connect } from 'react-redux';
import './css/App.css';

import Screen from './components/common/Screen';
import Main from './components/Main'
import MobileMenu from './components/common/MobileMenu'
import FormPopup from './components/common/FormPopup'

import { onSizeChange } from './actions';

class App extends Component {
    constructor(props) {
        super(props);
        this.component = React.createRef();
        this.timeoutID = -1;
    }
    render() {
        return <Screen className="screen"
            content={<Main />}
            overlay={this.getOverlay()}
            divRef={ this.component }
        />;
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', () => {this.handleResize()});
        
    }

    handleResize() {
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(()=>{
            if (!this.component.current) {
                return;
            }
            this.props.onSizeChange(
                this.component.current.offsetWidth,
                this.component.current.offsetHeight
            )
        }, 100)
    }

    getOverlay() {
        if (this.props.mobileShowMenu) {
            return <MobileMenu/>
        }

        if (this.props.formShowPopup) {
            return <FormPopup/>;
        }
        return null;
    }

}

const mapStateToProps = state => {
    return {
        mobileShowMenu: state.mobile.menuShow,
        formShowPopup: state.form.showPopup,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        onSizeChange: (width, height) => dispatch(onSizeChange(width, height)),
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(App);
