import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import Bullet from '../../images/bullet.svg';
import Img from '../../images/why_img.svg';
import Paragraph from '../common/Paragraph';
import BackgroundImg from '../../components/common/BackgroundImg';
import ParagraphAccordion from '../common/ParagraphAccordion';

import '../../css/components/paragraphs/Why.css';

import { ANCHOR_WHY, MOBILE_SIZE } from '../../constants';

class Why extends Component {
    constructor(props) {
        super(props);
        this.state = { imgLoaded: false };
    }
    render() {
        const texts = this.props.texts;
        const list = this.generateList(texts);

        if (this.props.size.width > MOBILE_SIZE ) {
            return (
                <Paragraph title={ texts.title } id={ ANCHOR_WHY } className='why'>
                    <BackgroundImg image={ Img } className='d-none d-lg-block'/>
                    <Row>
                        <Col className='d-none d-lg-block'></Col>
                        <Col>{ list }</Col>
                    </Row>
                    <Row className='d-block d-lg-none why-image-down'>
                        <Col>
                            <img  src={ Img } alt={ texts.title } onLoad={this.handleImageLoaded.bind(this)}></img>
                        </Col>
                    </Row>
                </Paragraph>
            );
        } else {
            const isToggle = (window.location.hash === '#' + ANCHOR_WHY )
            return (
                <ParagraphAccordion title={ texts.title } id={ ANCHOR_WHY } isToggle={ isToggle } className='why'>
                    <Row>
                        <Col>{ list }</Col>
                    </Row>
                    <Row className='why-image-down'>
                        <Col>
                            <img  src={ Img } alt={ texts.title } onLoad={this.handleImageLoaded.bind(this)}></img>
                        </Col>
                    </Row>
                </ParagraphAccordion>
            );
        }
        
    }
    
    generateList(texts) {
        return (
            <Row className='why-list'> 
                <Col> 
                    {texts.list.map( 
                        (item, i) => {
                            return (
                                <Row key={ item.title + i + 'image' }>
                                    <Col className='why-bullet' >
                                        <img src={ Bullet } alt={ item.title}></img>
                                    </Col>
                                    <Col  sm={ 10 }  xs={ 9 } key={ item.title + i } >
                                        <Row>
                                            <Col><h2>{ item.title }</h2></Col>
                                        </Row>
                                        <Row>
                                            <Col><p>{ item.text }</p></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        }
                    )}
                </Col>
            </Row>
    )};

    handleImageLoaded() {
        this.setState({imgLoaded:true});
    }
}

const mapStateToProps = state => {
    return {
        size:    state.size,
        texts:   state.texts.why,
    };
}

export default connect(mapStateToProps)(Why);