import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import Technologies from './paragraphs/Technologies';
import Projects from './paragraphs/Projects';
import Why from './paragraphs/Why';
import Testimonials from './paragraphs/Testimonials';
import Meeting from './paragraphs/Meeting';
import Contact from './paragraphs/Contact';


import { sendFormMock } from '../actions';

class Main extends Component {
    render() {
        return (
            <Container fluid={ false } className='main'>
                <Row>
                    <Col>
                        <Header/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Technologies texts={ this.props.technologiesTxt }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Projects texts={ this.props.projectsTxt }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Why/>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <Testimonials/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Meeting texts={ this.props.meetingTxt } onSubmit={ this.props.sendForm }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Contact texts={ this.props.contactTxt }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Footer/>
                    </Col>
                </Row>
            </Container>
        );
    } 
}

const mapStateToProps = state => {
    return {
        technologiesTxt:    state.texts.technologies,
        projectsTxt:        state.texts.projects,
        meetingTxt:         state.texts.meeting,
        contactTxt:         state.texts.contact,
        formStatus:         state.form.status,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        sendForm: values => dispatch(sendFormMock(values)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);