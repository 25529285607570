import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import { closeMobileMenu} from '../../actions'

import Menu from './Menu';
import Language from '../Language';

import '../../css/components/common/MobileMenu.css';

import CloseImg from '../../images/cancel_mobile.svg';
import Email from '../../images/email.svg';

class MobileMenu extends Component {
    render() {
        const menuTxt = this.props.menuTxt;
        const languageTxt = this.props.languageTxt;
        const contactTxt = this.props.contactTxt;
         return (
            <Container fluid={ true } className='mobile-menu h-100'>
                <Row className='h-75'>
                    <Col>
                        <Menu texts={ menuTxt } onClick={ this.props.onCloseButtonClick }/>
                    </Col>
                    <Col xs={3} className='d-flex justify-content-end align-items-start'>
                        <img src={ CloseImg } onClick={ this.props.onCloseButtonClick } alt='close'></img>
                    </Col>
                </Row>
                <Row className='h-25'>
                <Col>
                    <Row className='h-50'>
                        <Col className='d-flex justify-content-center align-items-end'>
                                <Language texts={ languageTxt } onClick={ this.props.onCloseButtonClick }/>
                        </Col>
                    </Row>
                    <Row className='h-50'>
                        <Col className='mobile-menu-email d-flex align-items-start justify-content-center'>
                            <div><img src={ Email } alt={ contactTxt.email }></img></div>
                            <div><a href={ `mailto:${ contactTxt.email }` }><p>{ contactTxt.email }</p></a></div>
                        </Col>
                    </Row>
                </Col>
                </Row>
            </Container>
        );
    } 
}

const mapStateToProps = state => {
    return {
        menuTxt:        state.texts.menu,
        languageTxt:    state.texts.language,
        contactTxt:     state.texts.contact,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onCloseButtonClick: () => dispatch(closeMobileMenu()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);