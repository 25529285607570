import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import Header from './Header'

class Card extends Component {
    render() {
        this.collapse = {};
        const isToggle = this.props.isToggle?true:false;
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (child.type === Header ) {
                return React.cloneElement(child, { onClick: (key, header) => this.onHeaderClick(key, header), isToggle })
            }

            const collRef = React.createRef();
            this.collapse[child.props.eventKey] = collRef;
            return React.cloneElement(child, { ref: collRef, isToggle })
        });

        const cm = this.props.className?this.props.className:'';

        return  <Row className={ `card-cm ${ cm }` }>
                    <Col>{ childrenWithProps }</Col>
                </Row>
    }

    onHeaderClick(key, header) {
        const isShown = this.collapse[key].current.toggle();
        header.toggled(isShown);
    }
}

export default Card;