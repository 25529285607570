import React,{Component} from 'react';
import { Row, Col } from 'react-bootstrap';

class Paragraph extends Component {
    render() {
        const title = this.props.title?this.props.title:'No title';
        const image = this.props.image;
        const className = this.props.className;
        return (
            <Row className='header-item'>
                <Col>
                    <Row className='header-item-image'>
                        <Col align='center'>
                            <img src={ image } className={ className } alt={ title }></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col align='center'>
                            <p>{ title }</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    } 
}

export default Paragraph;