 const EN = {
     "logo": {
        "shield": "Shield",
        "technologies": "Technologies",
     },
     "language": {
        "en": "EN",
        "se": "SE",
     },
     "header": {
        "title": "Web solutions for sustainable growth",
        "text": "We are great at creating digital solutions that help companies grow. Book a meeting and we will help you as well.",
        "bookBtn": "Book a meeting",
        "items": {
            "web": "Web Applications",
            "sales": "Sales Applications",
            "ecommerce": "E-commerce Stores",
            "mobile": "Mobile Applications",

        }
    },
    "menu": {
        "services": "Services",
        "projects": "Our Projects",
        "why": "Why Shields?",
        "contacts": "Contacts",
    },
    "technologies": {
        "title": "Technologies",
        "technologies": [{
                "title": "Frontend",
                "list": ["Javascript", "HTML", "CSS"]
            },{
                "title": "Backend",
                "list": ["Golang", "Rust", "Ruby", "PHP"]
            },{
                "title": "General Development",
                "list": ["C", "C++", "Java"]
            },{
                "title": "Mobile Development",
                "list": ["IOS", "Swift", "Java"]
            }
        ]
    },
    "projects":{
        "title": "Our projects",
    },
    "why":{
        "title": "Why Shield?",
        "list":[{
                "title": "You come first ",
                "text": "We work really hard to make sure you are happy with every part of this journey, that is the only thing that matters in the end."
            },{
                "title": "We love tech",
                "text": "We love technology, user experiences and design. Every line of code is written with love and every color is chosen with care."
            },{
                "title": "We love what we do",
                "text": "We are not only great at what we do, we have fun too. This is important since it make us go that extra mile."
            }
        ]
    },
    "testimonials": {
        "title":"Testimonials",
        "content":[
            {
                "title": "Gunnar",
                "sub": ["Cell Solar"],
                "text": "Here at Cell Solar we are very pleased with Shield Technologies and the result they delivered, the software is of high quality and the development progressed according to the plans."
            },{
                "title": "Anders",
                "sub": ["Xenter"],
                "text": "We are very pleased with Shield Technologies and are happy to recommend them to future assignments."
            }
        ]
    },
    "meeting": {
        "title": "Book a meeting",
        "text": "Send your contacts and we will get back to you witthin 24 hours!",
        "email": "Enter your e-mail",
        "phone": "Enter your phone number",
        "details": "Add details",
        "button": "Book now",
        "placeholders":{
            "email": "name@email.com",
            "phone": "+46 (__) __ - __ - ___",
            "details": "What are you interested in?",

        },
        "errors": {
            "requiredEmail": "Email is required",
            "invalidEmail": "Email is invalid",
            "requiredPhone": "Phone is required",
            "invalidPhone": "Phone number is invalid",
        },
        "form": {
            "title": "Thanks for contacting us!",
            "text": "We will get back to you within 24 hours",
        }
    },
    "contact": {
        "title": "Contact us",
        "address": "Sankt Eriksgatan 72, 113 20 Stockholm",
        "phone": "+46 732 688 937",
        "email": "info@theshield.se"
    },
    "footer": {
        "address": "Sankt Eriksgatan 72, 113 20 Stockholm",
        "phone": "+46 732 688 937",
        "email": "info@theshield.se",
        "copyright": "Copyright © 2021 Shield Technologies",
        "terms": "Terms of Service & Privacy Policy"
    }
};

export default EN;
